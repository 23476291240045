<template>

  <div
    v-if="$can('read', 'camerasmanagement')"
    class="mb-2"
  >
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <!-- Filters -->
      <!-- <logs-list-filters
        :role-filter.sync="roleFilter"
        :status-filter.sync="statusFilter"
        :role-options="roleOptions"
        :status-options="statusOptions"
      /> -->
      <!-- Table Top -->
      <div class="m-2">
        <!-- <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-button
              class="mr-2"
              variant="primary"
              :disabled="this.$store.state.logs.fetchingList"
              @click="handleRefreshList"
            >
              <feather-icon icon="RefreshCcwIcon" /><span class="text-nowrap ml-1">Refresh List</span>
            </b-button>
          </b-col>
        </b-row> -->

        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
            <b-button
              class="ml-2"
              variant="primary"
              :disabled="this.$store.state.logs.fetchingList"
              @click="handleRefreshList"
            >
              <feather-icon icon="RefreshCcwIcon" /><span class="text-nowrap ml-1">Refresh List</span>
            </b-button>
          </b-col>

          <!-- Search Query -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>
      </div>
      <!-- Items Table -->
      <b-table
        ref="refListTable"
        class="position-relative"
        :items="fetchItems"
        responsive
        :fields="tableColumns"
        primary-key="log_id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text=""
        :sort-desc.sync="isSortDirDesc"
        :busy.sync="isBusy"
        @row-clicked="handleListItemClick"
      >

        <!-- Column: command -->
        <template #cell(command)="data">
          <div>
            <span class="align-text-top text-capitalize">{{ resolveCommands(data.item.command) }}</span>
          </div>
        </template>

        <!-- Column: que_dt -->
        <template #cell(camera_date_time)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ formatDateTimeAMPM(data.item.que_dt.replace('T', ' ')) }} (UTC)</span>
          </div>
        </template>

      </b-table>

      <!-- Pagination -->
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
    <!-- JSON Modal -->
    <b-modal
      id="vmodal"
      ref="vmodal"
      ok-only
      ok-title="Close"
      centered
      size="lg"
      :title="jsonViewerTitle"
      @close="showJSONViewerModal = false"
    >
      <json-viewer
        :value="jsonData"
        :expanded="true"
        :expand-depth="10"
        :copyable="true"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BPagination, BModal, VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import JsonViewer from 'vue-json-viewer'
import useCommandsQuedLogsList from './useCommandsQuedLogsList'

export default {
  components: {
    // LogsListFilters,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BModal,
    JsonViewer,

    vSelect,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    cameraData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      clients: [],
      clientsList: [],

      jsonViewerTitle: '',
      showJSONViewerModal: false,
      jsonData: '',
    }
  },
  // computed: {
  //   isFetchingUserList() {
  //     console.log('isFetchingUserList')
  //     console.log(this.$store.getters.getFetchingUserList)
  //     return this.$store.getters.getFetchingUserList
  //   },
  // },
  methods: {
    handleRefreshList() {
      this.$store.state.logs.fetchingList = true
      this.refetchData()
    },
    handleListItemClick(record) {
      // Open JSON Viewer modal
      try {
        if (record.command !== 'et-phonehome') {
          this.jsonViewerTitle = `${record.camera_id} -- ${record.que_dt} -- ${record.expiry_epoch}`
          this.jsonData = JSON.parse(JSON.stringify(record.command))
          this.showJSONViewerModal = true
          this.$refs.vmodal.show()
        }
      } catch (err) {
        console.log(err)
      }
    },
  },
  setup(props) {
    console.log(props)

    const {
      fetchItems,
      tableColumns,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isBusy,
      isSortDirDesc,
      refListTable,
      refetchData,
      resolveCommands,

    } = useCommandsQuedLogsList(props.cameraData)

    const formatDateTimeAMPM = date => {
      const theDate = new Date(date.replace(/-/g, '/'))
      const year = theDate.getFullYear()
      let month = theDate.getMonth() + 1
      if (month < 10) month = `0${month}`
      let day = theDate.getDate()
      if (day < 10) day = `0${day}`
      let hours = theDate.getHours()
      let minutes = theDate.getMinutes()
      const ampm = hours >= 12 ? 'PM' : 'AM'
      hours %= 12
      hours = hours || 12
      minutes = minutes.toString().padStart(2, '0')
      const strTime = `${year}-${month}-${day} ${hours}:${minutes} ${ampm}`
      return strTime
    }

    return {
      fetchItems,
      tableColumns,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isBusy,
      isSortDirDesc,
      refListTable,
      refetchData,
      resolveCommands,
      formatDateTimeAMPM,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="css" scoped>
@import '../../../../@core/css/vue-json-viewer/style.css';
</style>
