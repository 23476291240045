import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
// import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ability from '../../../../libs/acl/ability'

// import { API } from 'aws-amplify'
// import AWSAuth from '@/auth/aws/useAWS'

export default function useCommandsSendLogsList(cameraData) {
  console.log('cameraData')
  console.log(cameraData)
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'command', sortable: false },
    { key: 'camera_date_time', sortable: true },
  ]
  const perPage = ref(10)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('time_camera')
  const isBusy = ref(false)
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItems.value,
    }
  })

  const refetchData = () => {
    refListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const fetchItems = (ctx, callback) => {
    console.log('fetchItems')
    if (ability.can('read', 'camerasmanagement')) {
      const qvars = {
        forceRefresh: store.state.logs.fetchingList,
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        camera_id: cameraData.camera_id,
        clientname: cameraData.clientname,
        media_type: 'command',
      }
      // console.log(qvars)
      store
        .dispatch('app-camera/fetchCameraLogs', qvars)
        .then(response => {
          const { Items } = response
          callback(Items)
          totalItems.value = response.Count
        })
        .catch(error => {
          console.log(error)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching logs list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
  }

  const resolveCommands = commands => {
    let myreturn = ''
    try {
      const theCommandsObj = JSON.parse(commands)
      const theCommands = theCommandsObj.Commands
      theCommands.forEach((element, index) => {
        myreturn = `${myreturn} ${element.message_type}`
        if (index < theCommands.length - 1) {
          myreturn = `${myreturn},`
        }
      })
    } catch (error) {
      myreturn = commands
    }
    return myreturn
  }

  return {
    fetchItems,
    tableColumns,
    perPage,
    currentPage,
    totalItems,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isBusy,
    isSortDirDesc,
    refListTable,
    refetchData,
    resolveCommands,
  }
}
