import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
// import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ability from '../../../../libs/acl/ability'

// import { API } from 'aws-amplify'
// import AWSAuth from '@/auth/aws/useAWS'

export default function useAlertsLogsList(cameraData) {
  console.log('cameraData')
  console.log(cameraData)
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'type', sortable: true },
    { key: 'battery', sortable: true },
    { key: 'antenna_signal', sortable: true },
    { key: 'exception', sortable: false },
    { key: 'state', sortable: false },
    { key: 'camera_date_time', sortable: true },
  ]
  const perPage = ref(10)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('time_camera')
  const isBusy = ref(false)
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItems.value,
    }
  })

  const refetchData = () => {
    refListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const fetchItems = (ctx, callback) => {
    console.log('fetchItems')
    if (ability.can('read', 'camerasmanagement')) {
      const qvars = {
        forceRefresh: store.state.logs.fetchingList,
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        camera_id: cameraData.camera_id,
        clientname: cameraData.clientname,
        media_type: 'alert',
      }
      // console.log(qvars)
      store
        .dispatch('app-camera/fetchCameraLogs', qvars)
        .then(response => {
          const { Items } = response
          callback(Items)
          totalItems.value = response.Count
        })
        .catch(error => {
          console.log(error)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching logs list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveBatteryIcon = batteryLife => {
    const batteryLife1 = batteryLife.replace('V', '')
    // determine which battery icon to use
    if (batteryLife1 === 10) {
      // set icon
    }
    return ['fas', 'battery-full']
  }

  const resolveBatteryColor = batteryLife => {
    let myRet = { color: 'white' }
    const batteryLife1 = batteryLife.replace('V', '')
    if (batteryLife1 <= 10) {
      myRet = { color: 'red' }
    } else if (batteryLife1 > 10 && batteryLife1 <= 10.8) {
      myRet = { color: 'orange' }
    } else if (batteryLife1 > 10.8 && batteryLife1 <= 11.7) {
      myRet = { color: 'blue' }
    } else if (batteryLife1 > 11.7) {
      myRet = { color: 'green' }
    }
    return myRet
  }

  const resolveBatteryVariant = batteryLife => {
    const batteryLife1 = batteryLife.replace('V', '')
    let myRet = batteryLife1 / 1
    myRet = myRet.toFixed(2)
    return `${myRet} v`
  }

  const resolveSignalIcon = antennaSignal => {
    const antennaSignal1 = antennaSignal
    // determine which signal icon to use
    if (antennaSignal1 === 10) {
      // set icon
    }
    return ['fas', 'signal']
  }

  const resolveSignalColor = antennaSignal => {
    const antennaSignal1 = antennaSignal
    let myRet = { color: 'white' }
    if (antennaSignal1 < -100) {
      myRet = { color: 'red' }
    } else if (antennaSignal1 < -90) {
      myRet = { color: 'orange' }
    } else if (antennaSignal1 < -80) {
      myRet = { color: 'yellow' }
    } else {
      myRet = { color: 'green' }
    }
    return myRet
  }

  const resolveSignalVariant = antennaSignal => `${antennaSignal} dBm`

  const resolveTypeVariant = type => {
    // if (type === 'Exception') return 'warning'
    if (type === 'Exception') return 'danger'
    if (type === 'Aux-In') return 'success'
    if (type === 'disabled') return 'warning'
    return 'success'
  }

  const resolveStateVariant = type => {
    // if (type === 'Exception') return 'warning'
    if (type === 'na') return 'warning'
    if (type === 'active') return 'success'
    if (type === 'disabled') return 'warning'
    return 'warning'
  }

  return {
    fetchItems,
    tableColumns,
    perPage,
    currentPage,
    totalItems,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isBusy,
    isSortDirDesc,
    refListTable,
    refetchData,

    resolveBatteryIcon,
    resolveBatteryColor,
    resolveBatteryVariant,
    resolveSignalIcon,
    resolveSignalColor,
    resolveSignalVariant,

    resolveTypeVariant,
    resolveStateVariant,
  }
}
