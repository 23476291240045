<template>
  <b-card no-body>
    <b-card-header>
      <!-- title and subtitle -->
      <div>
        <b-card-title class="mb-1">
          Signal History
        </b-card-title>
        <b-card-sub-title>Past 30 Days</b-card-sub-title>
      </div>
    </b-card-header>

    <b-card-body>
      <vue-apex-charts
        v-if="lineAreaChartSpline.series.length"
        type="area"
        height="400"
        :options="lineAreaChartSpline.chartOptions"
        :series="lineAreaChartSpline.series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardTitle, BCardSubTitle,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

export default {
  components: {
    BCard,
    VueApexCharts,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
  },
  props: {
    heartbeatData: {
      type: Array,
      default: null,
    },
  },
  setup() {
    const lineAreaChartSpline = ref(null)
    lineAreaChartSpline.value = {
      series: [
        {
          name: 'Battery Readings',
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          toolbar: {
            show: true,
          },
        },
        dataLabels: {
          style: {
            color: $themeColors.primary,
          },
          enabled: false,
        },
        stroke: {
          show: true,
          curve: 'straight',
          width: 1,
        },
        legend: {
          show: true,
          position: 'top',
          horizontalAlign: 'left',
          fontSize: '14px',
          fontFamily: 'Montserrat',
        },
        grid: {
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
        xaxis: {
          type: 'datetime',
          labels: {
            style: {
              colors: $themeColors.secondary,
            },
          },
          axisTicks: {
            show: true,
            borderType: 'solid',
            color: $themeColors.secondary,
            height: 6,
            offsetX: 0,
            offsetY: 0,
          },
        },
        yaxis: {
          // opposite: isRtl
          // reversed: true,
          min: -34,
          max: 0,
          labels: {
            style: {
              colors: $themeColors.secondary,
            },
          },
          axisTicks: {
            show: true,
            borderType: 'solid',
            color: $themeColors.secondary,
            height: 6,
            offsetX: 0,
            offsetY: 0,
          },
          title: {
            text: 'dB (RSRQ)',
            offsetX: 0,
            offsetY: 0,
            style: {
              color: $themeColors.secondary,
              fontSize: '14px',
            },
          },
          tooltip: {
            enabled: true,
            offsetX: 0,
          },
        },
        fill: {
          opacity: 0.5,
          type: 'solid',
        },
        tooltip: {
          shared: false,
        },
        colors: [$themeColors.primary],
      },
    }

    return {
      lineAreaChartSpline,
    }
  },
  created() {
    // Read through the heartbeat data and populate chart
    this.lineAreaChartSpline.series.data = []
    this.lineAreaChartSpline.chartOptions.xaxis.categories = []
    this.heartbeatData.forEach(item => {
      const theDate = new Date(item.time_camera.replace('T', ' '))
      const tmp = { x: theDate.getTime(), y: item.antenna_signal }
      this.lineAreaChartSpline.series[0].data.push(tmp)
    })
  },
}
</script>
