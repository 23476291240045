import { API } from 'aws-amplify'
import store from '@/store'
import ability from '../../../libs/acl/ability'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchCameras(ctx, qvars) {
      return new Promise((resolve, reject) => {
        if (ability.can('read', 'cameras') || ability.can('read', 'camerasmanagement')) {
          console.log('fetchCameras')
          console.log(qvars)
          const { forceRefresh } = qvars
          store.state.cameraconsole.fetchingCameraConsoleList = true
          const d1 = new Date()
          const timeDiff = d1.getTime()

          const resortItems = theItems => {
            // sort the items
            // let theSortBy = qvars.sortBy
            // if (theSortBy === 'ID') {
            //   theSortBy = 'camera_id'
            // }
            // if (qvars.sortDesc) {
            //   theItems.sort((a, b) => {
            //     console.log('sort anything desc')
            //     // console.log(a)
            //     // console.log(b)
            //     // console.log(theSortBy)
            //     return b[theSortBy].localeCompare(a[theSortBy])
            //   })
            // } else {
            //   theItems.sort((a, b) => {
            //     console.log('sort anything asc')
            //     // console.log(a)
            //     // console.log(b)
            //     // console.log(theSortBy)
            //     return a[theSortBy].localeCompare(b[theSortBy])
            //   })
            // }
            if (qvars.viewType === 'cameraBySites') {
              console.log('sort cameraBySites')
              theItems.sort((a, b) => a.sitename.localeCompare(b.sitename) || b.latest_alert_show - a.latest_alert_show || a.location_name.localeCompare(b.location_name))
            } else if (qvars.viewType === 'allCameras') {
              console.log('sort allCameras')
              theItems.sort((a, b) => b.latest_alert_show - a.latest_alert_show || a.sitename.localeCompare(b.sitename) || a.location_name.localeCompare(b.location_name))
            } else {
              console.log('sort allAlerts')
              theItems.sort((a, b) => b.latest_alert_show - a.latest_alert_show || a.sitename.localeCompare(b.sitename) || a.location_name.localeCompare(b.location_name))
            }
          }
          const paginateItems = theItems2 => {
            const start = (qvars.page - 1) * qvars.perPage
            const end = start + qvars.perPage
            return theItems2.slice(start, end)
          }
          const resortFilterItems = items => {
            const resultItems = []
            // first apply filters
            items.forEach(item => {
              let addThisItem = true
              console.log('qvars')
              if (qvars.userClientName !== null && qvars.userClientName !== '' && item.clientname !== qvars.userClientName) {
                addThisItem = false
              }
              if (qvars.sitename !== null && qvars.sitename !== '' && item.sitename !== qvars.sitename) {
                addThisItem = false
              }
              if (qvars.status !== null && item.status !== qvars.status) {
                addThisItem = false
              }
              if (qvars.viewType !== null && qvars.viewType === 'allAlerts' && item.latest_alert_show !== true) {
                addThisItem = false
              }
              if (qvars.q !== null
                && !item.camera_id.toUpperCase().includes(qvars.q.toUpperCase())
                && !item.unit_number.includes(qvars.q.toUpperCase())
                && !item.imei.toUpperCase().includes(qvars.q.toUpperCase())
                && !item.clientname.toUpperCase().includes(qvars.q.toUpperCase())
                && !item.sitename.toUpperCase().includes(qvars.q.toUpperCase())
                && !item.location_name.toUpperCase().includes(qvars.q.toUpperCase())
                && !item.status.toUpperCase().includes(qvars.q.toUpperCase())) {
                addThisItem = false
              }
              if (addThisItem) {
                resultItems.push(item)
              }
            })
            // resort items
            resortItems(resultItems)
            // Apply pagination and return
            return paginateItems(resultItems)
          }

          // Check for caching
          // console.log('cameras_console_lastRefresh')
          // console.log(parseInt(localStorage.getItem('cameras_console_lastRefresh'), 10) + 60000)
          // console.log(timeDiff)
          // console.log(parseInt(localStorage.getItem('cameras_console_lastRefresh'), 10) + 60000 < timeDiff)
          // console.log('forceRefresh')
          // console.log(forceRefresh)
          // console.log(localStorage.getItem('cameras_console'))
          if (
            forceRefresh
            || localStorage.getItem('cameras_console') === null
            || parseInt(localStorage.getItem('cameras_console_lastRefresh'), 10) + 60000 < timeDiff // cache for 60 seconds
          ) {
            console.log('Refreshing Cameras')
            const apiName = 'APIGateway'
            const path = '/camera'
            const myInit = { response: true }

            API.get(apiName, path, myInit)
              .then(response => {
                const theResponse = response
                const cameras = response.data
                cameras.forEach(element => {
                  if (element.is_online === false) {
                    // eslint-disable-next-line no-param-reassign
                    element.status = 'offline'
                  } else if (element.is_active === true) {
                    // eslint-disable-next-line no-param-reassign
                    element.status = 'online'
                  } else if (element.is_active === false) {
                    // eslint-disable-next-line no-param-reassign
                    element.status = 'disabled'
                  }
                })
                localStorage.setItem('cameras_console', JSON.stringify(cameras))
                localStorage.setItem('cameras_console_lastRefresh', d1.getTime())
                theResponse.data.cameras = resortFilterItems(cameras)
                theResponse.data.totalItems = cameras.length
                console.log(theResponse)
                store.state.cameraconsole.fetchingCameraConsoleList = false
                resolve(theResponse)
              })
              .catch(error => {
                store.state.cameraconsole.fetchingCameraConsoleList = false
                reject(error)
                if (error === 'No current user') {
                  this.$router.replace({ name: 'auth-login' })
                }
              })
          } else {
            console.log('Using Cached Camera Data')
            const theResponse = { data: {} }
            const cameraData = JSON.parse(localStorage.getItem('cameras_console'))
            theResponse.data.cameras = resortFilterItems(cameraData)
            theResponse.data.totalItems = cameraData.length
            console.log(theResponse)
            store.state.cameraconsole.fetchingCameraConsoleList = false
            resolve(theResponse)
          }
        } else {
          reject()
        }
      })
    },
    fetchCameraMedia(ctx, qvars) {
      return new Promise((resolve, reject) => {
        if (ability.can('read', 'cameras') || ability.can('read', 'camerasmanagement')) {
          console.log('fetchCameraMedia')
          console.log(qvars)
          const apiName = 'APIGateway'
          // console.log(clientname)
          const path = `/camera/${qvars.camera_id}/media`
          // const myInit = { response: true }
          const cameraQueryData = {
            clientname: qvars.clientname,
            get_date: qvars.get_date,
            time_zone: qvars.time_zone,
            media_type: qvars.media_type,
          }
          if (typeof qvars.start_time !== 'undefined' && qvars.start_time !== null) {
            cameraQueryData.start_time = qvars.start_time
          }
          if (typeof qvars.end_time !== 'undefined' && qvars.end_time !== null) {
            cameraQueryData.end_time = qvars.end_time
          }
          if (typeof qvars.start_key !== 'undefined') {
            cameraQueryData.start_key = qvars.start_key
          }
          console.log(cameraQueryData)

          API.post(apiName, path, { body: cameraQueryData })
            .then(response => {
              console.log('fetchCameraMedia Response:')
              console.log(response)
              const theResponse = response
              theResponse.Items.forEach((item, index) => {
                theResponse.Items[index].time_camera = item.time_camera.replace('T', ' ').replaceAll('-', '/')
              })
              resolve(theResponse)
            })
            .catch(error => {
              reject(error)
              if (error === 'No current user') {
                this.$router.replace({ name: 'auth-login' })
              }
            })
        } else {
          reject()
        }
      })
    },
    fetchCameraUUID(ctx, qvars) {
      return new Promise((resolve, reject) => {
        if (ability.can('update', 'cameras') || ability.can('update', 'camerasmanagement')) {
          console.log('fetchCameraUUID')
          console.log(qvars)
          const apiName = 'APIGateway'
          // console.log(clientname)
          const path = `/htmlembed/uuid/${qvars.camera_id}`

          API.get(apiName, path)
            .then(response => {
              // console.log(response)
              const theResponse = response
              resolve(theResponse)
            })
            .catch(error => {
              reject(error)
              if (error === 'No current user') {
                this.$router.replace({ name: 'auth-login' })
              }
            })
        } else {
          reject()
        }
      })
    },
    fetchLivePic(ctx, qvars) {
      return new Promise((resolve, reject) => {
        if (ability.can('read', 'cameras') || ability.can('read', 'camerasmanagement')) {
          console.log('fetchLivePic')
          console.log(qvars)
          const apiName = 'APIGateway'
          // console.log(clientname)
          const path = `/camera/${qvars.camera_id}/livepic`
          // const myInit = { response: true }
          const cameraQueryData = {
            clientname: qvars.clientname,
          }
          console.log(cameraQueryData)

          API.post(apiName, path, { body: cameraQueryData })
            .then(response => {
              // console.log(response)
              const theResponse = response
              theResponse.time_camera = theResponse.time_camera.replace('T', ' ').replaceAll('-', '/')
              resolve(theResponse)
            })
            .catch(error => {
              reject(error)
              if (error === 'No current user') {
                this.$router.replace({ name: 'auth-login' })
              }
            })
        } else {
          reject()
        }
      })
    },
    commandCamera(ctx, qvars) {
      return new Promise((resolve, reject) => {
        if (ability.can('update', 'camerasmanagement') || ability.can('read', 'cameras')) {
          console.log('commandCamera')
          const apiName = 'APIGateway'
          const path = `/camera/${qvars.camera_id}/command`
          let bodyData = {}
          if (qvars.command === 'TAKE_PICTURE') {
            bodyData = {
              message_type: qvars.command,
            }
          } else if (qvars.command === 'PTZ_CONTROL') {
            bodyData = {
              message_type: qvars.command,
              action: qvars.action,
            }
          } else if (qvars.command === 'RETRIEVE_VIDEO') {
            bodyData = {
              message_type: qvars.command,
              video_file: qvars.video_file,
            }
          } else if (qvars.command === 'FETCH_LOG') {
            bodyData = {
              message_type: qvars.command,
            }
          } else if (qvars.command === 'UPDATE_ENDPOINTS') {
            bodyData = {
              message_type: qvars.command,
            }
          } else if (qvars.command === 'PHONE_HOME') {
            bodyData = {
              message_type: qvars.command,
            }
          } else if (qvars.command === 'SOFTWARE_UPDATE') {
            bodyData = {
              message_type: qvars.command,
              device: qvars.device,
              version: qvars.version,
            }
          }

          console.log(bodyData)

          API.post(apiName, path, { body: bodyData })
            .then(response => {
              // console.log(response)
              const theResponse = response
              resolve(theResponse)
            })
            .catch(error => {
              reject(error)
              if (error === 'No current user') {
                this.$router.replace({ name: 'auth-login' })
              }
            })
        } else {
          reject()
        }
      })
    },
    addTimelapse(ctx, qvars) {
      return new Promise((resolve, reject) => {
        if (ability.can('create', 'cameras') || ability.can('create', 'camerasmanagement')) {
          console.log('commandCamera')
          const apiName = 'APIGateway'
          const path = `/camera/${qvars.camera_id}/timelapse`
          console.log(qvars)

          API.post(apiName, path, { body: qvars })
            .then(response => {
              console.log(response)
              const theResponse = response
              resolve(theResponse)
            })
            .catch(error => {
              reject(error)
              if (error === 'No current user') {
                this.$router.replace({ name: 'auth-login' })
              }
            })
        } else {
          reject()
        }
      })
    },
    fetchCameraMediaTimelapse(ctx, qvars) {
      return new Promise((resolve, reject) => {
        if (ability.can('read', 'cameras') || ability.can('read', 'camerasmanagement')) {
          console.log('fetchCameraMediaTimelapse')
          console.log(qvars)
          const { forceRefresh } = qvars
          store.state.cameraconsole.fetchingTimelapseList = true
          const d1 = new Date()
          const timeDiff = d1.getTime()

          const resortItems = theItems => {
            // sort by date time with latest first
            theItems.sort((b, a) => a.start_dt.localeCompare(b.start_dt))
          }
          const paginateItems = theItems2 => {
            const start = (qvars.page - 1) * qvars.perPage
            const end = start + qvars.perPage
            return theItems2.slice(start, end)
          }
          const resortFilterItems = items => {
            const resultItems = []
            // first apply filters
            items.forEach(item => {
              let addThisItem = true
              // console.log('qvars')
              // console.log(qvars)
              // console.log(item)
              if (typeof qvars.start_time !== 'undefined' && qvars.start_time !== null && qvars.start_time !== '') {
                const itemStartDateTime = new Date(item.start_dt)
                const qvarsStartTime = new Date(qvars.start_time.replace('T', ' ').replaceAll('-', '/'))
                if (itemStartDateTime.getTime() < qvarsStartTime.getTime()) {
                  console.log('failed start DT')
                  addThisItem = false
                }
              }
              if (typeof qvars.end_time !== 'undefined' && qvars.end_time !== null && qvars.end_time !== '') {
                const itemEndDateTime = new Date(item.end_dt)
                const qvarsEndTime = new Date(qvars.end_time.replace('T', ' ').replaceAll('-', '/'))
                if (itemEndDateTime.getTime() > qvarsEndTime.getTime()) {
                  console.log('failed end DT')
                  addThisItem = false
                }
              }
              console.log(addThisItem)
              if (addThisItem) {
                resultItems.push(item)
              }
            })
            console.log('filtered Items')
            console.log(resultItems)
            // resort items
            resortItems(resultItems)
            console.log('resorted Items')
            console.log(resultItems)
            // Apply pagination and return
            return paginateItems(resultItems)
          }

          // Check for caching
          // console.log('cameras_console_lastRefresh')
          // console.log(localStorage.getItem(`timelapse_${qvars.camera_id}`))
          // console.log(timeDiff)
          // console.log(parseInt(localStorage.getItem('cameras_console_lastRefresh'), 10) + 600000 < timeDiff)
          // console.log('forceRefresh')
          // console.log(forceRefresh)
          if (
            forceRefresh
            || localStorage.getItem(`timelapse_${qvars.camera_id}`) === null
            || parseInt(localStorage.getItem(`timelapse_${qvars.camera_id}_Refresh`), 10) + 600000 < timeDiff // cache for 10 Minutes
          ) {
            console.log('Refreshing Timelapse list')
            const apiName = 'APIGateway'
            // console.log(clientname)
            const path = `/camera/${qvars.camera_id}/media`
            // const myInit = { response: true }
            const cameraQueryData = {
              clientname: qvars.clientname,
              get_date: qvars.get_date,
              time_zone: qvars.time_zone,
              media_type: qvars.media_type,
            }
            // if (typeof qvars.start_time !== 'undefined' && qvars.start_time !== null) {
            //   cameraQueryData.start_time = qvars.start_time
            // }
            // if (typeof qvars.end_time !== 'undefined' && qvars.end_time !== null) {
            //   cameraQueryData.end_time = qvars.end_time
            // }
            console.log(cameraQueryData)

            API.post(apiName, path, { body: cameraQueryData })
              .then(response => {
                console.log('fetchCameraMediaTimelapse Response')
                const theResponse = response
                theResponse.Items.forEach((item, index) => {
                  theResponse.Items[index].start_dt = item.start_dt.replace('T', ' ').replaceAll('-', '/')
                  theResponse.Items[index].end_dt = item.end_dt.replace('T', ' ').replaceAll('-', '/')
                })
                localStorage.setItem(`timelapse_${qvars.camera_id}`, JSON.stringify(theResponse.Items))
                localStorage.setItem(`timelapse_${qvars.camera_id}_Refresh`, d1.getTime())
                theResponse.Items = resortFilterItems(theResponse.Items)
                theResponse.totalItems = theResponse.Items.length
                console.log(theResponse)
                store.state.cameraconsole.fetchingTimelapseList = false
                resolve(theResponse)
              })
              .catch(error => {
                store.state.cameraconsole.fetchingTimelapseList = false
                reject(error)
                if (error === 'No current user') {
                  this.$router.replace({ name: 'auth-login' })
                }
              })
          } else {
            console.log('Using Cached Timelapse Data')
            const theResponse = { }
            const timelapseItems = JSON.parse(localStorage.getItem(`timelapse_${qvars.camera_id}`))
            theResponse.Items = resortFilterItems(timelapseItems)
            theResponse.totalItems = timelapseItems.length
            console.log(theResponse)
            store.state.cameraconsole.fetchingTimelapseList = false
            resolve(theResponse)
          }
        } else {
          reject()
        }
      })
    },
    toggleLatest(ctx, qvars) {
      return new Promise((resolve, reject) => {
        if (ability.can('update', 'cameras') || ability.can('update', 'camerasmanagement')) {
          console.log('toggleLatest')
          console.log(qvars)
          const apiName = 'APIGateway'
          // console.log(clientname)
          const path = `/camera/${qvars.camera_id}/toggle_latest`
          // const myInit = { response: true }
          const cameraQueryData = {
            latest: qvars.latest,
          }
          console.log(cameraQueryData)

          API.patch(apiName, path, { body: cameraQueryData })
            .then(response => {
              // console.log(response)
              const theResponse = response
              // Update the camera list data in local storage
              const cameras = JSON.parse(localStorage.getItem('cameras_console'))
              const tmp = []
              cameras.forEach(element => {
                if (element.camera_id === qvars.camera_id) {
                  if (qvars.latest === 'alert') {
                    const tmp1 = element
                    tmp1.latest_alert_show = false
                    tmp.push(tmp1)
                  }
                } else {
                  tmp.push(element)
                }
              })
              localStorage.setItem('cameras_console', JSON.stringify(tmp))
              // send resolve
              resolve(theResponse)
            })
            .catch(error => {
              reject(error)
              if (error === 'No current user') {
                this.$router.replace({ name: 'auth-login' })
              }
            })
        } else {
          reject()
        }
      })
    },
  },
}
