<template>
  <div v-if="$can('read', 'cameras') || $can('read', 'camerasmanagement')">

    <!-- Top Information -->
    <b-media class="mb-2">
      <h4 class="mb-1">
        <feather-icon
          icon="CameraIcon"
          size="22"
          class="mr-50"
        />
        Camera Settings -- {{ cameraData.sitename }}, {{ cameraData.location_name }}<br>
      </h4>
      <small>Camera: {{ cameraData.unit_number }}</small>
      <b-badge
        v-if="!cameraData.is_active"
        pill
        variant="light-danger"
        class="text-capitalize ml-1"
      >
        Disabled
      </b-badge>
      <b-badge
        v-else-if="!cameraData.is_online"
        pill
        variant="light-warning"
        class="text-capitalize ml-1"
      >
        Offline
      </b-badge>
      <b-badge
        v-else
        pill
        variant="light-success"
        class="text-capitalize ml-1"
      >
        Online
      </b-badge>
      <br>
    </b-media>
    <b-row class="mt-2 mb-1 border-bottom-primary">
      <b-col>
        <h4>Current Status</h4>
      </b-col>
    </b-row>
    <b-row class="mb-1">
      <b-col
        md="6"
      >
        Last Communication: {{ formatDateTimeAMPM(cameraData.last_communication.local.replace('T', ' ').replace('Z', '')) }} ({{ cameraData.time_zone }})
      </b-col>
      <b-col
        md="6"
      >
        Camera Time Zone: {{ cameraData.time_zone }}
      </b-col>
    </b-row>
    <b-row class="mb-1">
      <b-col
        md="6"
      >
        Firmware: v{{ cameraData.current_status.firmware.version }} -- <small>Updated: {{ formatDateTimeAMPM(cameraData.current_status.firmware.updated_on.replace('T', ' ')) }}</small>
      </b-col>
      <b-col
        md="6"
      >
        Picture Interval: {{ cameraData.settings.capture_interval }} minutes
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col
        md="6"
      >
        Motion Detection: {{ cameraData.settings.is_motion_detection_enabled }}
      </b-col>
    </b-row>
    <!-- Battery  -->
    <b-row class="border-top-secondary">
      <b-col class="mt-1">
        <h4>Battery Status</h4>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="col-12 col-md-4 mb-2">
        <b-card-header>
          <!-- title and subtitle -->
          <div>
            <b-card-title class="mb-1">
              Latest Reading: {{ Number(cameraData.current_status.battery) }}v
            </b-card-title>
            <b-card-sub-title>{{ formatDateTimeAMPM(cameraData.last_communication.local.replace('T', ' ').replace('Z', '')) }} ({{ cameraData.time_zone }})</b-card-sub-title>
          </div>
        </b-card-header>
        <vue-apex-charts
          type="radialBar"
          :options="goalOverviewRadialBar.chartOptions"
          :series="goalOverviewRadialBar.series"
        />
      </b-col>
      <b-col class="col-12 col-md-8">
        <battery-history-line-area-chart
          :heartbeat-data="heartbeatData"
        />
      </b-col>
    </b-row>
    <!-- Signal Strength -->
    <b-row class="border-top-secondary">
      <b-col class="mt-1">
        <h4>Signal Strength</h4>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col class="col-12 col-md-4 mb-2">
        <b-card-header>
          <!-- title and subtitle -->
          <div>
            <b-card-title class="mb-1">
              Latest Reading: {{ cameraData.current_status.antenna_signal }}dB (RSRQ)
            </b-card-title>
            <b-card-sub-title>{{ formatDateTimeAMPM(cameraData.last_communication.local.replace('T', ' ').replace('Z', '')) }} ({{ cameraData.time_zone }})</b-card-sub-title>
          </div>
        </b-card-header>
        <vue-apex-charts
          type="bar"
          :options="salesBar.chartOptions"
          :series="salesBar.series"
        />
      </b-col>
      <b-col class="col-12 col-md-8">
        <signal-history-line-area-chart
          :heartbeat-data="heartbeatData"
        />
      </b-col>
    </b-row>
    <!-- Solar Power -->
    <b-row
      v-if="isNaN(cameraData.current_status.solar_current)"
    />
    <b-row
      v-else
      class="border-top-secondary"
    >
      <b-col class="mt-1">
        <h4>Solar Current</h4>
      </b-col>
    </b-row>
    <b-row
      v-if="isNaN(cameraData.current_status.solar_current)"
    />
    <b-row
      v-else
      class="mb-4"
    >
      <b-col class="col-12 col-md-4 mb-2">
        <b-card-header>
          <!-- title and subtitle -->
          <div>
            <b-card-title class="mb-1">
              Latest Reading: {{ Number(cameraData.current_status.solar_current) }} mA
            </b-card-title>
            <b-card-sub-title>{{ formatDateTimeAMPM(cameraData.last_communication.local.replace('T', ' ').replace('Z', '')) }} ({{ cameraData.time_zone }})</b-card-sub-title>
          </div>
        </b-card-header>
        <vue-apex-charts
          type="radialBar"
          :options="solarRadialBar.chartOptions"
          :series="solarRadialBar.series"
        />
      </b-col>
      <b-col class="col-12 col-md-8">
        <solar-history-line-area-chart
          :heartbeat-data="heartbeatData"
        />
      </b-col>
    </b-row>
  </div>

</template>

<script>
import {
  BMedia, BRow, BCol, BBadge, BCardHeader, BCardTitle, BCardSubTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import BatteryHistoryLineAreaChart from '../../../analytics/cameras/BatteryHistoryLineAreaChart.vue'
import SignalHistoryLineAreaChart from '../../../analytics/cameras/SignalHistoryLineAreaChart.vue'
import SolarHistoryLineAreaChart from '../../../analytics/cameras/SolarHistoryLineAreaChart.vue'
// import VueGauge from 'vue-gauge'

export default {
  components: {
    BMedia,
    BRow,
    BCol,
    BBadge,
    VueApexCharts,
    BatteryHistoryLineAreaChart,
    SignalHistoryLineAreaChart,
    SolarHistoryLineAreaChart,
    BCardHeader,
    BCardTitle,
    BCardSubTitle,

    // VueGauge,
  },
  props: {
    cameraData: {
      type: Object,
      default: null,
    },
    heartbeatData: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      status: '',
      options: {},
      goalOverviewRadialBar: {
        series: [],
        chartOptions: {
          chart: {
            sparkline: {
              enabled: true,
            },
            dropShadow: {
              enabled: true,
              blur: 3,
              left: 1,
              top: 1,
              opacity: 0.1,
            },
          },
          colors: [$themeColors.success],
          plotOptions: {
            radialBar: {
              offsetY: -10,
              startAngle: -120,
              endAngle: 120,
              hollow: {
                size: '77%',
              },
              track: {
                dropShadow: {
                  enabled: true,
                  top: 2,
                  left: 0,
                  blur: 4,
                  opacity: 0.15,
                },
              },
              dataLabels: {
                name: {
                  offsetY: -30,
                  color: $themeColors.secondary,
                  fontSize: '14px',
                },
                value: {
                  offsetY: -10,
                  color: $themeColors.secondary,
                  fontSize: '22px',
                  show: true,
                },
              },
            },
          },
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'horizontal',
              shadeIntensity: 0.5,
              gradientToColors: [$themeColors.success],
              inverseColors: false,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 50, 100],
            },
          },
          stroke: {
            lineCap: 'round',
          },
          labels: ['Battery'],
          grid: {
            padding: {
              bottom: 30,
            },
          },
        },
      },
      salesBar: {
        series: [
          {
            name: 'RSRQ',
            data: [1, 3, 7, 10, 15, 23, 34],
          },
        ],
        chartOptions: {
          chart: {
            sparkline: { enabled: true },
            toolbar: { show: false },
            dropShadow: {
              enabled: true,
              blur: 3,
              left: 1,
              top: 1,
              opacity: 0.1,
            },
          },
          grid: {
            show: false,
            padding: {
              left: 0,
              right: 0,
            },
          },
          states: {
            hover: {
              filter: 'none',
            },
          },
          colors: [
            $themeColors.primary,
            $themeColors.primary,
            $themeColors.primary,
            $themeColors.primary,
            $themeColors.primary,
            $themeColors.primary,
            '#ebf0f7',
          ],
          plotOptions: {
            bar: {
              columnWidth: '45%',
              distributed: true,
              endingShape: 'rounded',
            },
          },
          tooltip: {
            x: { show: false },
          },
          xaxis: {
            type: 'numeric',
          },
        },
      },
      signalOptions: {},
      solarRadialBar: {
        series: [],
        chartOptions: {
          chart: {
            sparkline: {
              enabled: true,
            },
            dropShadow: {
              enabled: true,
              blur: 3,
              left: 1,
              top: 1,
              opacity: 0.1,
            },
          },
          colors: [$themeColors.success],
          plotOptions: {
            radialBar: {
              offsetY: -10,
              startAngle: -120,
              endAngle: 120,
              hollow: {
                size: '77%',
              },
              track: {
                dropShadow: {
                  enabled: true,
                  top: 2,
                  left: 0,
                  blur: 4,
                  opacity: 0.15,
                },
              },
              dataLabels: {
                name: {
                  offsetY: -30,
                  color: $themeColors.secondary,
                  fontSize: '14px',
                },
                value: {
                  offsetY: -10,
                  color: $themeColors.secondary,
                  fontSize: '22px',
                  show: true,
                },
              },
            },
          },
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'horizontal',
              shadeIntensity: 0.5,
              gradientToColors: [$themeColors.success],
              inverseColors: false,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 50, 100],
            },
          },
          stroke: {
            lineCap: 'round',
          },
          labels: ['Solar Current'],
          grid: {
            padding: {
              bottom: 30,
            },
          },
        },
      },
      theImage: '',
    }
  },
  setup(props) {
    console.log('Camera Status Setup Started')
    console.log(props.cameraData)
    const formatDateTimeAMPM = date => {
      const theDate = new Date(date.replace(/-/g, '/'))
      const year = theDate.getFullYear()
      let month = theDate.getMonth() + 1
      if (month < 10) month = `0${month}`
      let day = theDate.getDate()
      if (day < 10) day = `0${day}`
      let hours = theDate.getHours()
      let minutes = theDate.getMinutes()
      const ampm = hours >= 12 ? 'PM' : 'AM'
      hours %= 12
      hours = hours || 12
      minutes = minutes.toString().padStart(2, '0')
      const strTime = `${year}-${month}-${day} ${hours}:${minutes} ${ampm}`
      return strTime
    }

    return {
      formatDateTimeAMPM,
    }
  },
  created() {
    console.log('Camera Status Created Started')
    // console.log(Number(this.cameraData.current_status.battery))
    const battReading = ((Number(this.cameraData.current_status.battery) - 9.6) / 2.8) * 100
    this.goalOverviewRadialBar.series.push(battReading.toFixed(2))
    // this.goalOverviewRadialBar.series.push(50)
    this.options = {
      chartWidth: 400,
      needleValue: battReading,
      hasNeedle: true,
      needleColor: 'black',
      needleStartValue: battReading,
      arcColors: ['rgb(197,58,55)', 'rgb(222,112,8)', 'rgb(0, 0, 255)', 'rgb(61,204,91)'],
      arcDelimiters: [76.9, 83, 90],
      arcOverEffect: false,
      arcLabels: ['10v', '10.8v', '11.7v'],
      rangeLabel: ['9.6v', '12.4v'],
      rangeLabelFontSize: 22,
    }
    // Battery Range - 9.6v to 12.4v

    // const antennaSignal = (Number(this.cameraData.current_status.antenna_signal) / 34) * 100
    const antennaSignal = Number(this.cameraData.current_status.antenna_signal)
    console.log(`this.cameraData.current_status.antenna_signal: ${this.cameraData.current_status.antenna_signal}`)
    console.log(`antennaSignal: ${antennaSignal}`)
    this.signalOptions = {
      chartWidth: 400,
    }
    this.salesBar.chartOptions.colors = []
    if (antennaSignal > -34) {
      this.salesBar.chartOptions.colors.push($themeColors.danger)
    } else {
      this.salesBar.chartOptions.colors.push('#ebf0f7')
    }
    if (antennaSignal > -25) {
      this.salesBar.chartOptions.colors.push($themeColors.danger)
    } else {
      this.salesBar.chartOptions.colors.push('#ebf0f7')
    }
    if (antennaSignal > -21) {
      this.salesBar.chartOptions.colors.push($themeColors.warning)
    } else {
      this.salesBar.chartOptions.colors.push('#ebf0f7')
    }
    if (antennaSignal > -18) {
      this.salesBar.chartOptions.colors.push($themeColors.warning)
    } else {
      this.salesBar.chartOptions.colors.push('#ebf0f7')
    }
    if (antennaSignal >= -15) {
      this.salesBar.chartOptions.colors.push($themeColors.success)
    } else {
      this.salesBar.chartOptions.colors.push('#ebf0f7')
    }
    if (antennaSignal > -13) {
      this.salesBar.chartOptions.colors.push($themeColors.success)
    } else {
      this.salesBar.chartOptions.colors.push('#ebf0f7')
    }
    if (antennaSignal >= -10) {
      this.salesBar.chartOptions.colors.push($themeColors.success)
    } else {
      this.salesBar.chartOptions.colors.push('#ebf0f7')
    }
    // Signal Range - RSRQ - 0 to 34

    const solarReading = ((Number(this.cameraData.current_status.solar_current)) / 5000) * 100
    this.solarRadialBar.series.push(solarReading.toFixed(2))
  },
  methods: {
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
