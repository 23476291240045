<template>
  <div v-if="$can('update', 'cameras') || $can('update', 'camerasmanagement')">

    <!-- Media -->
    <b-media class="mb-2">
      <h4 class="mb-1">
        <feather-icon
          icon="CameraIcon"
          size="22"
          class="mr-50"
        />
        Camera Notifications -- {{ cameraData.sitename }}, {{ cameraData.location_name }}<br>
      </h4>
      <small>Camera: {{ cameraData.unit_number }}</small><br>
    </b-media>

    <!-- User Info: Input Fields -->
    <validation-observer
      ref="observer"
      #default="{ handleSubmit }"
    >
      <b-form
        @submit.prevent="handleSubmit(validateForm)"
      >
        <b-row class="mt-2 mb-1 border-bottom-primary">
          <b-col>
            <h4>Email Notifications</h4>
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col>
            <b-form-checkbox
              id="send_email_motion_only"
              v-model="cameraData.notification.send_email_motion_only"
              name="send_email_motion_only"
            >
              Send email on Motion Detection Only
            </b-form-checkbox>
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col>
            <b-form-checkbox
              id="send_email_after_hours"
              v-model="cameraData.notification.send_email_after_hours"
              name="send_email_after_hours"
            >
              Send 6PM to 6AM Only
            </b-form-checkbox>
          </b-col>
          <b-col>
            <b-form-checkbox
              id="attach_image_on_emails"
              v-model="cameraData.notification.attach_image_on_emails"
              name="attach_image_on_emails"
            >
              Attach Images to Email
            </b-form-checkbox>
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col>
            <b-table
              striped
              responsive
              :items="emailNotificationsList"
              class="mb-0"
            >

              <template #cell(fullName)="data">
                {{ data.value }}
              </template>
              <template #cell(emailAddress)="data">
                {{ data.value }}
              </template>
              <template #cell()="data">
                <b-form-checkbox
                  :id="data.value.name"
                  v-model="selectedEmails"
                  :name="data.value.name"
                  :checked="data.value.value"
                  :value="data.value.checkedvalue"
                  :unchecked-value="data.value.uncheckedvalue"
                  @change="updateEmailList()"
                />
              </template>

            </b-table>
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <!-- Field: Additional Email List -->
          <b-col>
            <b-form-group
              label="Additional Emails: One email addresses per line"
              label-for="email_list_additional"
            >
              <validation-provider
                #default="{ errors }"
                name="email_list_additional"
              >
                <b-form-textarea
                  id="email_list_additional"
                  v-model="email_list_additional_display"
                  rows="3"
                  max-rows="6"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mt-3 mb-1 border-bottom-primary">
          <b-col>
            <h4>SMS Notifications</h4>
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col>
            NOTE: SMS messages are sent on Motion Detection Only
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col>
            <b-form-checkbox
              id="send_sms_after_hours"
              v-model="cameraData.notification.send_sms_after_hours"
              name="send_sms_after_hours"
            >
              Send 6PM to 6AM Only
            </b-form-checkbox>
          </b-col>
          <!-- <b-col>
            <b-form-checkbox
              id="attach_image_for_sms"
              v-model="cameraData.notification.attach_image_for_sms"
              name="attach_image_for_sms"
            >
              Attach Images to SMS
            </b-form-checkbox>
          </b-col> -->
        </b-row>

        <b-row class="mt-2">
          <b-col>
            <b-table
              striped
              responsive
              :items="smsNotificationsList"
              class="mb-0"
            >

              <template #cell(fullName)="data">
                {{ data.value }}
              </template>
              <template #cell(phoneNumber)="data">
                {{ data.value }}
              </template>
              <template #cell()="data">
                <b-form-checkbox
                  :id="data.value.name"
                  v-model="selectedSMS"
                  :name="data.value.name"
                  :checked="data.value.value"
                  :value="data.value.checkedvalue"
                  :unchecked-value="data.value.uncheckedvalue"
                  @change="updateSMSList()"
                />
              </template>

            </b-table>
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <!-- Field: Additional SMS List -->
          <b-col>
            <b-form-group
              label="Additional Phone Numbers: One phone number per line"
              label-for="sms_list_additional"
            >
              <validation-provider
                #default="{ errors }"
                name="sms_list_additional"
              >
                <b-form-textarea
                  id="sms_list_additional"
                  v-model="sms_list_additional_display"
                  rows="3"
                  max-rows="6"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col
            md="4"
          >
            <!-- Action Buttons -->
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              block
              type="submit"
            >
              Save Changes
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// Notification
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import {
  BButton, BMedia, BRow, BCol, BForm, BTable, BFormCheckbox, BFormTextarea, BFormGroup,
} from 'bootstrap-vue'
import ability from '../../../../libs/acl/ability'

export default {
  components: {
    BButton,
    BMedia,
    BRow,
    BCol,
    BForm,
    BTable,
    BFormCheckbox,
    BFormTextarea,
    BFormGroup,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    cameraData: {
      type: Object,
      default: null,
    },
    users: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      notificationsData: [],
      emailNotificationsList: [],
      smsNotificationsList: [],
      selectedEmails: [],
      selectedSMS: [],
      email_list_additional_display: '',
      sms_list_additional_display: '',
    }
  },
  created() {
    this.selectedEmails = []
    this.selectedSMS = []
    // Setup the notificationsData array
    if (typeof this.cameraData.notification !== 'undefined') {
      this.emailList = this.cameraData.notification.email_list
      this.smsList = this.cameraData.notification.sms_list
    }
    if (typeof this.cameraData.notification.email_list_additional !== 'undefined') {
      this.email_list_additional_display = ''
      this.cameraData.notification.email_list_additional.forEach(item => {
        this.email_list_additional_display = `${this.email_list_additional_display}${item}\n`
      })
    }
    if (typeof this.cameraData.notification.sms_list_additional !== 'undefined') {
      this.sms_list_additional_display = ''
      this.cameraData.notification.sms_list_additional.forEach(item => {
        this.sms_list_additional_display = `${this.sms_list_additional_display}${item}\n`
      })
    }

    if (typeof this.users !== 'undefined' && this.users !== null && this.users.length > 0) {
      this.formatNotificationTableData()
    }
  },
  methods: {
    formatNotificationTableData() {
      console.log('formatNotificationTableData')
      let tmp = {}
      let tmp1 = {}
      const formatPhoneNumber = phone => {
        if (phone.length === 10) return `(${phone.substring(0, 3)}) ${phone.substring(3, 6)}-${phone.substring(6, 10)}`
        if (phone.length === 11) return `${phone.substring(0, 1)} (${phone.substring(1, 4)}) ${phone.substring(4, 7)}-${phone.substring(7, 11)}`
        return phone
      }

      this.users.forEach(item => {
        // Setup Email List
        tmp = { fullName: item.fullName }
        tmp.emailAddress = item.email
        tmp.notification = {
          name: `${item.username}-notification`, value: `${item.username}-notification-false`, checkedvalue: `${item.username}-notification-true`, uncheckedvalue: `${item.username}-notification-false`,
        }

        if (typeof this.emailList !== 'undefined') {
          this.emailList.forEach(item1 => {
            if (item.username === item1.username) {
              tmp.notification = {
                name: `${item.username}-notification`, value: `${item.username}-notification-true`, checkedvalue: `${item.username}-notification-true`, uncheckedvalue: `${item.username}-notification-false`,
              }
              this.selectedEmails.push(`${item.username}-notification-true`)
            }
          })
        }
        this.emailNotificationsList.push(tmp)

        // Setup SMS list
        tmp1 = { fullName: item.fullName }
        tmp1.phoneNumber = formatPhoneNumber(item.phone)
        tmp1.notification = {
          name: `${item.username}-SMSnotification`, value: `${item.username}-SMSnotification-false`, checkedvalue: `${item.username}-SMSnotification-true`, uncheckedvalue: `${item.username}-SMSnotification-false`,
        }

        if (typeof this.smsList !== 'undefined') {
          this.smsList.forEach(item1 => {
            if (item.username === item1.username) {
              tmp1.notification = {
                name: `${item.username}-SMSnotification`, value: `${item.username}-SMSnotification-true`, checkedvalue: `${item.username}-SMSnotification-true`, uncheckedvalue: `${item.username}-SMSnotification-false`,
              }
              this.selectedSMS.push(`${item.username}-SMSnotification-true`)
            }
          })
        }
        this.smsNotificationsList.push(tmp1)
      })
      // console.log('this.smsNotificationsList')
      // console.log(this.smsNotificationsList)
    },

    updateEmailList() {
      // console.log('updateEmailList')
      const notifications = []
      // sort the list of selectedEmails abilities and loop through to format in final JSON format before saving
      this.selectedEmails.sort()
      this.users.forEach(item => {
        this.selectedEmails.forEach(item1 => {
          const parts = item1.split('-')
          if (parts[0] === item.username) {
            notifications.push({ username: item.username, email: item.email })
          }
        })
      })
      // Update the user data before sending
      this.cameraData.notification.email_list = notifications
      // console.log(this.cameraData.notification.email_list)
    },

    updateSMSList() {
      // console.log('updateSMSList')
      const notifications = []
      // sort the list of selectedEmails abilities and loop through to format in final JSON format before saving
      this.selectedSMS.sort()
      this.users.forEach(item => {
        this.selectedSMS.forEach(item1 => {
          const parts = item1.split('-')
          if (parts[0] === item.username) {
            notifications.push({ username: item.username, phone: item.phone })
          }
        })
      })
      // Update the user data before sending
      this.cameraData.notification.sms_list = notifications
      // console.log(this.cameraData.notification.sms_list)
    },

    validateForm() {
      console.log('validateForm')
      if (ability.can('update', 'cameras') || ability.can('update', 'camerasmanagement')) {
        this.updateEmailList()
        this.updateSMSList()
        console.log(this.cameraData)
        const cameraDataLimited = {}
        cameraDataLimited.camera_id = this.cameraData.camera_id
        console.log(typeof this.cameraData.notification.email_list)
        if (typeof this.cameraData.notification.attach_image_for_sms === 'undefined') {
          this.cameraData.notification.attach_image_for_sms = false
        }
        if (typeof this.cameraData.notification.attach_image_on_emails === 'undefined') {
          this.cameraData.notification.attach_image_on_emails = false
        }
        if (typeof this.cameraData.notification.email_list === 'undefined') {
          this.cameraData.notification.email_list = []
        }
        if (typeof this.email_list_additional_display !== 'undefined') {
          const tmp = this.email_list_additional_display.replaceAll(' ', '').split(/\r?\n/)
          this.cameraData.notification.email_list_additional = []
          tmp.forEach(item => {
            if (item.length > 0) {
              this.cameraData.notification.email_list_additional.push(item)
            }
          })
          console.log(this.cameraData.notification.email_list_additional)
        }
        if (typeof this.cameraData.notification.send_email_after_hours === 'undefined') {
          this.cameraData.notification.send_email_after_hours = false
        }
        if (typeof this.cameraData.notification.send_email_motion_only === 'undefined') {
          this.cameraData.notification.send_email_motion_only = false
        }
        if (typeof this.cameraData.notification.send_sms_motion_only === 'undefined') {
          this.cameraData.notification.send_sms_motion_only = true
        }
        if (typeof this.cameraData.notification.send_sms_after_hours === 'undefined') {
          this.cameraData.notification.send_sms_after_hours = false
        }
        if (typeof this.cameraData.notification.sms_list === 'undefined') {
          this.cameraData.notification.sms_list = []
        }
        if (typeof this.sms_list_additional_display !== 'undefined') {
          const tmp = this.sms_list_additional_display.replaceAll('-', '')
            .replaceAll('(', '')
            .replaceAll(')', '')
            .replaceAll(' ', '')
            .split(/\r?\n/)
          this.cameraData.notification.sms_list_additional = []
          tmp.forEach(item => {
            console.log(Number.isNaN(item))
            console.log(item.length)
            if (Number.isNaN(item) === false && item.length === 10) {
              this.cameraData.notification.sms_list_additional.push(item)
            }
          })
          console.log(this.cameraData.notification.sms_list_additional)
        }
        cameraDataLimited.notification = this.cameraData.notification
        cameraDataLimited.orig_cameraData = this.cameraData
        console.log(cameraDataLimited)
        store.dispatch('app-camera/updateCamera', cameraDataLimited)
          .then(response => {
            console.log(response)
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Camera Updated',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          })
          .catch(error => {
            console.log(error)
            let theErrorStr = 'The following error(s) where received:\n'
            if (typeof error.response.data.errors !== 'undefined') {
              error.response.data.errors.forEach(item => {
                theErrorStr += `${item.message}\n`
              })
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error updating Camera information.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: theErrorStr,
              },
            })
          })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
