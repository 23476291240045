<template>

  <div
    v-if="$can('read', 'camerasmanagement')"
    class="mb-2"
  >
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <!-- Filters -->
      <!-- <logs-list-filters
        :role-filter.sync="roleFilter"
        :status-filter.sync="statusFilter"
        :role-options="roleOptions"
        :status-options="statusOptions"
      /> -->
      <!-- Table Top -->
      <div class="m-2">
        <!-- <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-button
              class="mr-2"
              variant="primary"
              :disabled="this.$store.state.logs.fetchingList"
              @click="handleRefreshList"
            >
              <feather-icon icon="RefreshCcwIcon" /><span class="text-nowrap ml-1">Refresh List</span>
            </b-button>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <div
              v-if="$can('update', 'camerasmanagement')"
              class="d-flex align-items-center justify-content-end"
            >
              <b-button
                variant="primary"
                @click="handleDownloadCameraLog"
              >
                <feather-icon icon="DownloadIcon" /><span class="text-nowrap ml-1">Request Latest Log</span>
              </b-button>
            </div>
          </b-col>
        </b-row> -->

        <b-row>
          <!-- Per Page -->
          <b-col
            cols="8"
            md="8"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
            <b-button
              class="ml-2"
              variant="primary"
              :disabled="this.$store.state.logs.fetchingList"
              @click="handleRefreshList"
            >
              <feather-icon icon="RefreshCcwIcon" /><span class="text-nowrap ml-1">Refresh</span>
            </b-button>
            <b-button
              class="ml-2"
              variant="primary"
              @click="handleDownloadCameraLog"
            >
              <feather-icon icon="DownloadIcon" /><span class="text-nowrap ml-1">Request</span>
            </b-button>
          </b-col>

          <!-- Search Query -->
          <b-col
            cols="4"
            md="4"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>
      </div>
      <!-- Items Table -->
      <b-table
        ref="refListTable"
        class="position-relative"
        :items="fetchItems"
        responsive
        :fields="tableColumns"
        primary-key="log_id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text=""
        :sort-desc.sync="isSortDirDesc"
        :busy.sync="isBusy"
      >

        <!-- Column: camera_id -->
        <template #cell(camera_id)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.camera_id }}</span>
          </div>
        </template>

        <!-- Column: Client Name -->
        <template #cell(clientname)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.clientname }}</span>
          </div>
        </template>

        <!-- Column: sitename -->
        <template #cell(sitename)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.sitename }}</span>
          </div>
        </template>

        <!-- Column: time_camera -->
        <template #cell(date_time_downloaded)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ formatDateTimeAMPM(data.item.time_camera.replace('T', ' ')) }}</span>
          </div>
        </template>

        <!-- Column: download -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <b-button
              class="mr-2"
              variant="primary"
              @click="handleListItemClick(data.item)"
            >
              <feather-icon icon="DownloadIcon" /><span class="text-nowrap ml-1">Download</span>
            </b-button>
            <!-- <span class="align-text-top text-capitalize">{{ data.item.time_camera.replace('T', ' ') }}</span> -->
          </div>
        </template>
      </b-table>

      <!-- Pagination -->
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import { Storage } from 'aws-amplify'
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useCameraLogsList from './useCameraLogsList'

export default {
  components: {
    // LogsListFilters,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,

    vSelect,
  },
  props: {
    cameraData: {
      type: Object,
      default: null,
    },
    doRefresh: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      clients: [],
      clientsList: [],

      jsonViewerTitle: '',
      showJSONViewerModal: false,
      jsonData: '',
    }
  },
  // computed: {
  //   isFetchingUserList() {
  //     console.log('isFetchingUserList')
  //     console.log(this.$store.getters.getFetchingUserList)
  //     return this.$store.getters.getFetchingUserList
  //   },
  // },
  watch: {
    doRefresh() {
      if (this.doRefresh) {
        this.handleRefreshList()
        this.doRefresh = false
      }
    },
  },
  methods: {
    handleRefreshList() {
      this.$store.state.logs.fetchingList = true
      this.refetchData()
    },
    handleDownloadCameraLog() {
      // Call API endpoint to tell camera to send latest log to backend
      console.log('handleDownloadCameraLog')
      const qvars1 = {
        camera_id: this.cameraData.camera_id,
        command: 'FETCH_LOG',
      }

      store
        .dispatch('app-cameraconsole/commandCamera', qvars1)
        .then(response1 => {
          console.log('app-cameraconsole/commandCamera')
          console.log(response1)
          // TODO: Need to add AWS IoT listening to know when the picture is taken and refresh the main image with the new image
          // Also need to get an updated list of image media for the camera if past a certain time
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Command sent to camera. You will be notified when log is retreived.',
              icon: 'CameraIcon',
              variant: 'warning',
            },
          })
        })
        .catch(error => {
          console.log(error)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Error sending command to camera. ${error}`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    handleListItemClick(record) {
      console.log('handleListItemClick')
      console.log(record)
      // First get the camera log file
      let accessURL = record.access_url
      if (accessURL.indexOf('public/')) {
        accessURL = accessURL.slice(accessURL.indexOf('public/') + 7)
      } else if (accessURL.indexOf('Public')) {
        accessURL = accessURL.slice(accessURL.indexOf('Public/') + 7)
      } else if (accessURL.indexOf('PUBLIC')) {
        accessURL = accessURL.slice(accessURL.indexOf('PUBLIC/') + 7)
      }
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Downloading, please await ...',
          icon: 'DownloadIcon',
          variant: 'warning',
        },
      })
      const result = Storage.get(accessURL, { download: true }).then(result1 => {
        console.log(result1)
        console.log(result)
        const theLogFIleName = `${this.cameraData.sitename}-${this.cameraData.location_name}-${record.time_camera}.log`
        const fileUrl = window.URL.createObjectURL(result1.Body)
        const fileLink = document.createElement('a')
        fileLink.href = fileUrl
        fileLink.setAttribute('download', theLogFIleName)
        document.body.appendChild(fileLink)
        fileLink.click()
      })
    },
  },
  setup(props) {
    console.log(props)

    const {
      fetchItems,
      tableColumns,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isBusy,
      isSortDirDesc,
      refListTable,
      refetchData,

    } = useCameraLogsList(props.cameraData)

    const formatDateTimeAMPM = date => {
      const theDate = new Date(date.replace(/-/g, '/'))
      const year = theDate.getFullYear()
      let month = theDate.getMonth() + 1
      if (month < 10) month = `0${month}`
      let day = theDate.getDate()
      if (day < 10) day = `0${day}`
      let hours = theDate.getHours()
      let minutes = theDate.getMinutes()
      const ampm = hours >= 12 ? 'PM' : 'AM'
      hours %= 12
      hours = hours || 12
      minutes = minutes.toString().padStart(2, '0')
      const strTime = `${year}-${month}-${day} ${hours}:${minutes} ${ampm}`
      return strTime
    }

    return {
      fetchItems,
      tableColumns,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isBusy,
      isSortDirDesc,
      refListTable,
      refetchData,
      formatDateTimeAMPM,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
