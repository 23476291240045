<template>
  <div>

    <!-- Media -->
    <b-media class="mb-2">
      <h4 class="mb-1">
        <feather-icon
          icon="CameraIcon"
          size="22"
          class="mr-50"
        />
        Camera Permissions -- {{ cameraData.sitename }}, {{ cameraData.location_name }}<br>
      </h4>
      <small>Camera: {{ cameraData.unit_number }}</small><br>
    </b-media>

    <!-- User Info: Input Fields -->
    <validation-observer
      ref="observer"
      #default="{ handleSubmit }"
    >
      <b-form
        @submit.prevent="handleSubmit(validateForm)"
      >
        <b-row class="mt-2">
          <b-col>
            <h4>User Permissions</h4>
          </b-col>
        </b-row>

        <b-row v-if="usersPermissionsList.length > 0">
          <b-col>
            <b-table
              striped
              responsive
              :items="usersPermissionsList"
              class="mb-0"
            >

              <template #cell(fullName)="data">
                {{ data.value }}
              </template>
              <template #cell(username)="data">
                {{ data.value }}
              </template>
              <template #cell()="data">
                <b-form-select
                  :id="data.item.username"
                  v-model="data.item.permissions.current.value"
                  :options="data.item.permissions.theItems"
                  :clearable="false"
                  :input-id="data.item.username"
                  @input="updatePermissions"
                />
              </template>
              <!-- :v-model="`this.selected.${data.value.username}`" -->
            </b-table>
          </b-col>
        </b-row>

        <b-row
          v-if="usersPermissionsList.length > 0"
          class="mt-2"
        >
          <b-col
            md="4"
          >
            <!-- Action Buttons -->
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              block
              type="submit"
            >
              Save Changes
            </b-button>
          </b-col>
        </b-row>
        <b-row
          v-else
          class="mt-2 ml-1"
        >
          No users found. Please add users to the account before managing camera permissions.
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BButton, BMedia, BRow, BCol, BForm, BTable, BFormSelect,
} from 'bootstrap-vue'
// import vSelect from 'vue-select'
import ability from '../../../../libs/acl/ability'

export default {
  components: {
    BButton,
    BMedia,
    BRow,
    BCol,
    BForm,
    BTable,
    BFormSelect,
    // vSelect,
    ValidationObserver,
  },
  props: {
    cameraData: {
      type: Object,
      default: null,
    },
    users: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      usersPermissionsList: [],
      selected: [],
      theitems: [
        {
          text: 'No Access',
          value: 'noAccess',
        },
        {
          text: 'Permissions Based Access',
          value: 'view',
        },
      ],
    }
  },
  created() {
    // Loop through the eligible users and define the permissions data
    if (typeof this.users !== 'undefined' && this.users !== null && this.users.length > 0) {
      let tmp = {}
      this.selected = []

      this.users.forEach(item => {
        tmp = { fullName: item.fullName }
        tmp.username = item.username
        tmp.permissions = {}
        tmp.permissions.current = { text: 'No Access', value: { text: 'No Access', theVal: 'noAccess', username: item.username } }
        tmp.permissions.theItems = [
          { text: 'No Access', value: { text: 'No Access', theVal: 'noAccess', username: item.username } },
          // { text: 'Administrator', value: { text: 'Administrator', theVal: 'admin', username: item.username } },
          { text: 'Permissions Based Access', value: { text: 'Permissions Based Access', theVal: 'view', username: item.username } },
        ]
        // set the current permission if it exists for the user
        if (typeof this.cameraData.user_permissions !== 'undefined') {
          // console.log('Found user_permissions Data for Camera')
          this.cameraData.user_permissions.forEach(item1 => {
            // console.log('this.cameraData.user_permissions loop')
            if (item.username === item1.username) {
              if (item1.access_level === 'admin') {
                // console.log('Found admin')
                // tmp.permissions.current = { text: 'Administrator', value: { text: 'Administrator', theVal: 'admin', username: item.username } }
              } else if (item1.access_level === 'view') {
                // console.log('Found view')
                tmp.permissions.current = { text: 'Permissions Based Access', value: { text: 'Permissions Based Access', theVal: 'view', username: item.username } }
              }
            }
          })
        }
        this.usersPermissionsList.push(tmp)
        console.log(this.usersPermissionsList)
      })
      // console.log('this.usersPermissionsList')
      // console.log(this.usersPermissionsList)
      // console.log(this.selected)
    }
  },
  methods: {
    updatePermissions(a) {
      console.log('updatePermissions')
      console.log(a)
      console.log(this.usersPermissionsList)
      console.log(this.cameraData.user_permissions)
      // Update the user Permissions List so that the select drop down stays set properly
      // Update the permissions in the camera data at the same time
      const tmp = []
      this.usersPermissionsList.forEach((item, index) => {
        if (item.username === a.username) {
          this.usersPermissionsList[index].permissions.current = { text: a.text, value: a }
        }
        if (this.usersPermissionsList[index].permissions.current.value.theVal !== 'noAccess') {
          tmp.push({ username: item.username, access_level: this.usersPermissionsList[index].permissions.current.value.theVal })
        }
      })
      // console.log(this.usersPermissionsList)
      // console.log(tmp)
      this.cameraData.user_permissions = tmp
      console.log(this.usersPermissionsList)
      console.log(this.cameraData.user_permissions)
    },
    validateForm() {
      console.log('validateForm')
      if (ability.can('update', 'cameras') || ability.can('update', 'camerasmanagement')) {
        console.log(this.usersPermissionsList)
        const cameraDataLimited = {}
        cameraDataLimited.camera_id = this.cameraData.camera_id
        cameraDataLimited.user_permissions = this.cameraData.user_permissions
        cameraDataLimited.orig_cameraData = this.cameraData
        console.log(cameraDataLimited)
        store.dispatch('app-camera/updateCamera', cameraDataLimited)
          .then(response => {
            console.log(response)
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Camera Updated',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          })
          .catch(error => {
            console.log(error)
            let theErrorStr = 'The following error(s) where received:\n'
            if (typeof error.response.data.errors !== 'undefined') {
              error.response.data.errors.forEach(item => {
                theErrorStr += `${item.message}\n`
              })
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error updating Camera information.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: theErrorStr,
              },
            })
          })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
