<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >
    <!-- Status Tab -->
    <b-tab
      v-if="$can('read', 'cameras') || $can('read', 'camerasmanagement')"
      active
    >
      <!-- title -->
      <template #title>
        <feather-icon
          icon="ActivityIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Current Status</span>
      </template>

      <div v-if="heartbeatData">
        <component :is="cameraData === undefined ? 'div' : 'b-card'">

          <!-- Alert: No item found -->
          <b-alert
            variant="danger"
            :show="cameraData === undefined"
          >
            <h4 class="alert-heading">
              Error fetching camera data
            </h4>
            <div class="alert-body">
              No camera found with this cameraname.
            </div>
          </b-alert>

          <camera-edit-tab-status
            :camera-data="cameraData"
            :heartbeat-data="heartbeatData"
          />
        </component>
      </div>
    </b-tab>
    <!-- Settings Tab -->
    <b-tab
      v-if="$can('update', 'cameras') || $can('update', 'camerasmanagement')"
      :active="selectedTabName === 'settings'"
    >
      <!-- title -->
      <template #title>
        <feather-icon
          icon="SettingsIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Settings</span>
      </template>

      <div v-if="cameraData">
        <component :is="cameraData === undefined ? 'div' : 'b-card'">
          <camera-edit-tab-general
            :camera-data="cameraData"
            @goToTab="goToTab"
          />
        </component>
      </div>
    </b-tab>
    <!-- Scheduler Tab -->
    <b-tab v-if="($can('update', 'cameras') || $can('update', 'camerasmanagement')) && cameraData && cameraData.is_active">
      <!-- title -->
      <template #title>
        <feather-icon
          icon="ClockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Scheduler</span>
      </template>
      <div v-if="users">
        <component :is="(cameraData === undefined || users === undefined) ? 'div' : 'b-card'">
          <camera-edit-tab-scheduler
            :camera-data="cameraData"
            :users="users"
          />
        </component>
      </div>
    </b-tab>
    <!-- Notifications Tab -->
    <b-tab v-if="($can('update', 'cameras') || $can('update', 'camerasmanagement')) && cameraData && cameraData.is_active">
      <!-- title -->
      <template #title>
        <feather-icon
          icon="AlertTriangleIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Notifications</span>
      </template>
      <div v-if="users">
        <component :is="(cameraData === undefined || users === undefined) ? 'div' : 'b-card'">
          <camera-edit-tab-notifications
            :camera-data="cameraData"
            :users="users"
          />
        </component>
      </div>
    </b-tab>
    <!-- Permissions Tab -->
    <b-tab v-if="($can('update', 'cameras') || $can('update', 'camerasmanagement')) && cameraData && cameraData.is_active">
      <!-- title -->
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Permissions</span>
      </template>
      <div v-if="users">
        <component :is="(cameraData === undefined || users === undefined) ? 'div' : 'b-card'">
          <camera-edit-tab-permissions
            :camera-data="cameraData"
            :users="users"
          />
        </component>
      </div>
    </b-tab>
    <!-- Logs Tab -->
    <b-tab v-if="$can('read', 'camerasmanagement')">
      <!-- title -->
      <template #title>
        <feather-icon
          icon="DatabaseIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Logs</span>
      </template>
      <div v-if="users">
        <component :is="(cameraData === undefined || users === undefined) ? 'div' : 'b-card'">
          <camera-edit-tab-logs
            :camera-data="cameraData"
            :users="users"
          />
        </component>
      </div>
    </b-tab>
    <!-- Commands Tab -->
    <b-tab v-if="$can('update', 'camerasmanagement') && userData.group === 'Management'">
      <!-- title -->
      <template #title>
        <feather-icon
          icon="CommandIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Commands</span>
      </template>
      <div v-if="cameraData">
        <component :is="(cameraData === undefined || users === undefined) ? 'div' : 'b-card'">
          <camera-edit-tab-commands
            :camera-data="cameraData"
          />
        </component>
      </div>
    </b-tab>
  </b-tabs>
</template>

<script>
import {
  BRow, BCol, BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'

import { API } from 'aws-amplify'

import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import { getUserData } from '@/auth/utils'
import cameraStoreModule from '../cameraStoreModule'
import cameraConsoleStoreModule from '../../cameraconsole/cameraConsoleStoreModule'
import clientStoreModule from '../../clients/clientStoreModule'
import userStoreModule from '../../user/userStoreModule'
import firmwareStoreModule from '../../firmware/firmwareStoreModule'
import CameraEditTabStatus from './CameraEditTabStatus.vue'
import CameraEditTabGeneral from './CameraEditTabGeneral.vue'
import CameraEditTabPermissions from './CameraEditTabPermissions.vue'
import CameraEditTabNotifications from './CameraEditTabNotifications.vue'
import CameraEditTabScheduler from './CameraEditTabScheduler.vue'
import CameraEditTabLogs from './CameraEditTabLogs.vue'
import CameraEditTabCommands from './CameraEditTabCommands.vue'
import ability from '../../../../libs/acl/ability'

export default {
  components: {
    BRow,
    BCol,
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    CameraEditTabStatus,
    CameraEditTabGeneral,
    CameraEditTabPermissions,
    CameraEditTabNotifications,
    CameraEditTabScheduler,
    CameraEditTabLogs,
    CameraEditTabCommands,
  },
  props: {
    cameraid: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      selectedTabName: 'content',
    }
  },
  setup() {
    const cameraData = ref(null)
    const users = ref(null)
    const heartbeatData = ref(null)
    const userData = getUserData()

    const CAMERA_APP_STORE_MODULE_NAME = 'app-camera'
    // Register module
    if (!store.hasModule(CAMERA_APP_STORE_MODULE_NAME)) store.registerModule(CAMERA_APP_STORE_MODULE_NAME, cameraStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CAMERA_APP_STORE_MODULE_NAME)) store.unregisterModule(CAMERA_APP_STORE_MODULE_NAME)
    })

    const STORE_MODULE_NAME = 'app-cameraconsole'
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, cameraConsoleStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const CLIENT_APP_STORE_MODULE_NAME = 'app-client'
    // Register module
    if (!store.hasModule(CLIENT_APP_STORE_MODULE_NAME)) store.registerModule(CLIENT_APP_STORE_MODULE_NAME, clientStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CLIENT_APP_STORE_MODULE_NAME)) store.unregisterModule(CLIENT_APP_STORE_MODULE_NAME)
    })

    const USER_APP_STORE_MODULE_NAME = 'app-user'
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const FIRMWARE_APP_STORE_MODULE_NAME = 'app-firmware'
    // Register module
    if (!store.hasModule(FIRMWARE_APP_STORE_MODULE_NAME)) store.registerModule(FIRMWARE_APP_STORE_MODULE_NAME, firmwareStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(FIRMWARE_APP_STORE_MODULE_NAME)) store.unregisterModule(FIRMWARE_APP_STORE_MODULE_NAME)
    })

    if (ability.can('read', 'camerasmanagement')) {
      console.log(router.currentRoute.params.cameraid)
      const apiName = 'APIGateway'
      const path = `/camera/${router.currentRoute.params.cameraid}`
      // const path = '/camera/'
      const myInit = { response: true }

      API.get(apiName, path, myInit)
        .then(response => {
          cameraData.value = response.data
          // eslint-disable-next-line no-param-reassign
          cameraData.value.status = cameraData.value.is_active

          // Need to get related users list for this client
          const qvars = {
            client_name: cameraData.value.clientname,
          }
          console.log(qvars)
          store
            .dispatch('app-user/fetchClientRelatedUsers', qvars)
            .then(response1 => {
              users.value = response1.users
              console.log('app-user/fetchClientRelatedUsers')
              console.log(users)
            })
            .catch(error => {
              console.log(error)
              // this.$toast({
              //   component: ToastificationContent,
              //   props: {
              //     title: 'Error fetching clients list',
              //     icon: 'AlertTriangleIcon',
              //     variant: 'danger',
              //   },
              // })
            })

          const qvars2 = {
            forceRefresh: false,
            q: '',
            perPage: 720,
            page: 1,
            sortBy: '',
            sortDesc: true,
            camera_id: router.currentRoute.params.cameraid,
            clientname: cameraData.value.clientname,
            media_type: 'heartbeat',
          }
          console.log('Start Fetch heartbeat logs')
          console.log(qvars2)
          store
            .dispatch('app-camera/fetchCameraLogs', qvars2)
            .then(response2 => {
              heartbeatData.value = response2.Items
              console.log('Fetch heartbeat logs')
              console.log(heartbeatData.value)
            })
            .catch(error => {
              console.log(error)
            })
        })
        .catch(error => {
          console.log(error)
          if (typeof error.response !== 'undefined' && error.response.status === 404) {
            cameraData.value = undefined
          }
        })
    }

    return {
      cameraData,
      users,
      userData,
      heartbeatData,
    }
  },
  methods: {
    goToTab(tabName) {
      console.log(`goToTab: ${tabName}`)
      this.selectedTabName = tabName
    },
  },
}
</script>

<style>

</style>
