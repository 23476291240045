<template>
  <div v-if="$can('update', 'cameras') || $can('update', 'camerasmanagement')">

    <!-- Media -->
    <b-media class="mb-2">
      <template #aside>
        <!-- TODO: Show the latest Image here -->
      </template>
      <h4 class="mb-1">
        <feather-icon
          icon="CameraIcon"
          size="22"
          class="mr-50"
        />
        Camera Settings -- {{ cameraData.sitename }}, {{ cameraData.location_name }}<br>
      </h4>
      <small>Camera: {{ cameraData.unit_number }}</small><br>
    </b-media>

    <!-- User Info: Input Fields -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="observer"
    >
      <b-form
        @submit.prevent="handleSubmit(validateForm)"
      >
        <!-- Common Commands -->
        <b-row class="mt-2 mb-1 border-bottom-primary">
          <b-col>
            <h4>Common Commands</h4>
          </b-col>
        </b-row>
        <b-row class="mt-2 mb-4">
          <b-col
            md="3"
          >
            <b-button
              v-if="$can('update', 'camerasmanagement')"
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              block
              @click="phoneHome"
            >
              Phone Home
            </b-button>
          </b-col>
          <b-col
            md="3"
          >
            <b-button
              v-if="$can('update', 'camerasmanagement')"
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              block
              @click="updateServerAddress"
            >
              Update Camera Endpoints
            </b-button>
          </b-col>
        </b-row>

        <!-- Firmware Update -->
        <b-row
          class="mt-2 mb-1 border-bottom-primary"
        >
          <b-col>
            <h4>Firmware Update</h4>
          </b-col>
        </b-row>
        <b-row
          class="mb-1"
        >
          <b-col>
            Current version: {{ cameraData.current_status.firmware.version }}<br>
            Updated: {{ cameraData.current_status.firmware.updated_on.replace('T', ' ').replace('Z', '') }}
          </b-col>
        </b-row>
        <b-row
          v-if="firmwareVersionOptions !== null && firmwareVersionOptions.length"
        >
          <b-col
            md="6"
          >
            <b-form-group
              label="Firmware Version"
              label-for="firmware_version"
            >
              <validation-provider
                #default="{ errors }"
                name="firmware_version"
                rules="required"
              >
                <v-select
                  :key="firmwareVersion"
                  v-model="firmwareVersion"
                  :options="firmwareVersionOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="firmware_version"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row
          v-if="firmwareVersionOptions"
          class="mb-4"
        >
          <b-col
            md="3"
          >
            <b-button
              v-if="$can('update', 'camerasmanagement')"
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              block
              @click="updateFirmware"
            >
              Update Firmware
            </b-button>
          </b-col>
        </b-row>

        <!-- Manual Command -->
        <b-row class="mt-2 mb-1 border-bottom-primary">
          <b-col>
            <h4>Send Manual Command</h4>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            md="12"
          >
            <b-form-group
              label="Manual Command"
              label-for="manual_command"
            >
              <validation-provider
                #default="{ errors }"
                name="manual_command"
              >
                <b-form-textarea
                  id="manual_command"
                  v-model="currentManualCommand"
                  rows="3"
                  max-rows="6"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col
            md="3"
          >
            <b-button
              v-if="$can('update', 'camerasmanagement')"
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              block
              @click="sendManualCommand"
            >
              Send Manual Command
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import {
  BButton, BMedia, BRow, BCol, BFormGroup, BForm, BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ability from '../../../../libs/acl/ability'

export default {
  components: {
    BButton,
    BMedia,
    BRow,
    BCol,
    BFormGroup,
    BFormTextarea,
    BForm,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    cameraData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      currentManualCommand: '',
    }
  },
  setup(props) {
    // Use toast
    const toast = useToast()
    // Get the firmware versions available based on Device
    console.log(props.cameraData.camera_type)
    let cameraType = ''
    if (typeof props.cameraData.camera_type === 'undefined' || props.cameraData.camera_type === null || props.cameraData.camera_type.length === 0) {
      cameraType = 'PiCamera'
    } else {
      cameraType = props.cameraData.camera_type
    }
    cameraType = 'PiCamera'
    const firmwareVersion = ref(null)
    const firmwareVersionOptions = ref(null)
    if (ability.can('update', 'camerasmanagement')) {
      const qvars = {
        forceRefresh: true,
        q: '',
        perPage: 0,
        page: 1,
        sortBy: 'version',
        sortDesc: true,
        deviceType: cameraType,
      }
      console.log(qvars)
      store
        .dispatch('app-firmware/fetchFirmwareList', qvars)
        .then(response => {
          console.log(response)
          const { items } = response.data
          const tmp = []
          items.forEach(item => {
            tmp.push({
              label: item.version,
              value: item.version,
              default: false,
            })
          })
          firmwareVersionOptions.value = tmp
          console.log('firmwareVersionOptions')
          console.log(firmwareVersionOptions)
          firmwareVersion.value = tmp[0].value
        })
        .catch(error => {
          console.log(error)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching Firmware list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    return {
      firmwareVersionOptions,
      firmwareVersion,
      cameraType,
    }
  },
  created() {
  },
  methods: {
    validateForm() {
      console.log('validateForm')
    },
    phoneHome() {
      console.log('phone home')
      if (ability.can('update', 'camerasmanagement')) {
        this.$bvModal
          .msgBoxConfirm('Please confirm that you want to have the camera Phone Home.', {
            title: 'Phone Home',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Yes',
            cancelTitle: 'No',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if (value) {
              console.log(this.cameraData)
              const qvars1 = {
                camera_id: this.cameraData.camera_id,
                command: 'PHONE_HOME',
              }

              store
                .dispatch('app-cameraconsole/commandCamera', qvars1)
                .then(response1 => {
                  console.log('app-cameraconsole/commandCamera')
                  console.log(response1)
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Command sent to camera.',
                      icon: 'CameraIcon',
                      variant: 'warning',
                    },
                  })
                })
                .catch(error => {
                  console.log(error)
                  this.showLoadingOverlay = false
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `Error sending command to camera. ${error}`,
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                    },
                  })
                })
            }
          })
      }
    },
    updateServerAddress() {
      console.log('update Camera Endpoints')
      if (ability.can('update', 'camerasmanagement')) {
        this.$bvModal
          .msgBoxConfirm('Please confirm that you want to have the camera update its Camera Endpoints.', {
            title: 'Update Camera Endpoints',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Yes',
            cancelTitle: 'No',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if (value) {
              console.log(this.cameraData)
              const qvars1 = {
                camera_id: this.cameraData.camera_id,
                command: 'UPDATE_ENDPOINTS',
              }

              store
                .dispatch('app-cameraconsole/commandCamera', qvars1)
                .then(response1 => {
                  console.log('app-cameraconsole/commandCamera')
                  console.log(response1)
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Update Camera Endpoints command sent to camera.',
                      icon: 'CameraIcon',
                      variant: 'warning',
                    },
                  })
                })
                .catch(error => {
                  console.log(error)
                  this.showLoadingOverlay = false
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `Error sending command to camera. ${error}`,
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                    },
                  })
                })
            }
          })
      }
    },
    updateFirmware() {
      console.log('update Firmware')
      if (ability.can('update', 'camerasmanagement')) {
        this.$bvModal
          .msgBoxConfirm(`Please confirm that you want to have the camera update its Firmware to version ${this.firmwareVersion}.`, {
            title: 'Update Firmware',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Yes',
            cancelTitle: 'No',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if (value) {
              console.log(this.cameraData)
              const qvars1 = {
                camera_id: this.cameraData.camera_id,
                command: 'SOFTWARE_UPDATE',
                device: this.cameraType,
                version: this.firmwareVersion,
              }

              store
                .dispatch('app-cameraconsole/commandCamera', qvars1)
                .then(response1 => {
                  console.log('app-cameraconsole/commandCamera')
                  console.log(response1)
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `Update Firmware command sent to camera. Version: ${this.firmwareVersion}.`,
                      icon: 'CameraIcon',
                      variant: 'warning',
                    },
                  })
                })
                .catch(error => {
                  console.log(error)
                  this.showLoadingOverlay = false
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `Error sending command to camera. ${error}`,
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                    },
                  })
                })
            }
          })
      }
    },
    sendManualCommand() {
      console.log('send Manual Command')
      if (ability.can('update', 'camerasmanagement')) {
        let isJson = true
        try {
          JSON.parse(this.currentManualCommand)
        } catch (e) {
          isJson = false
        }
        if (isJson) {
          this.$bvModal
            .msgBoxConfirm('Please confirm that you want to send the manual command', {
              title: 'Send Manual Command',
              size: 'sm',
              okVariant: 'primary',
              okTitle: 'Yes',
              cancelTitle: 'No',
              cancelVariant: 'outline-secondary',
              hideHeaderClose: false,
              centered: true,
            })
            .then(value => {
              if (value) {
                console.log(this.cameraData)
                const qvars1 = {
                  camera_id: this.cameraData.camera_id,
                  command: this.currentManualCommand,
                }

                store
                  .dispatch('app-camera/sendManualCommand', qvars1)
                  .then(response1 => {
                    console.log('app-camera/sendManualCommand')
                    console.log(response1)
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: 'Manual Command sent to camera.',
                        icon: 'CameraIcon',
                        variant: 'warning',
                      },
                    })
                  })
                  .catch(error => {
                    console.log(error)
                    this.showLoadingOverlay = false
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: `Error sending manual command to camera. ${error}`,
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                      },
                    })
                  })
              }
            })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Command is not valid JSON',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
