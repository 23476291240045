<template>
  <div>
    <!-- Top Control Buttons -->
    <b-row class="mb-2">
      <b-col cols="8">
        <h4 class="mb-1">
          <feather-icon
            icon="DatabaseIcon"
            size="22"
            class="mr-50"
          />
          Logs -- {{ cameraData.sitename }}, {{ cameraData.location_name }}<br>
        </h4>
        <small>Camera: {{ cameraData.unit_number }}</small>
        <b-badge
          v-if="!cameraData.is_active"
          pill
          variant="light-danger"
          class="text-capitalize ml-1"
        >
          Disabled
        </b-badge>
        <br>
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col>
        <b-tabs v-model="tabIndex">
          <!-- Alerts -->
          <b-tab active>
            <template #title>
              <feather-icon
                icon="AlertOctagonIcon"
                size="18"
                class="mr-50"
              />
              <span class="font-weight-bold">Alerts</span>
            </template>
            <div>
              <camera-edit-tab-alerts-logs
                :camera-data="cameraData"
              />
            </div>
          </b-tab>
          <!-- Boot -->
          <b-tab>
            <template #title>
              <feather-icon
                icon="FastForwardIcon"
                size="18"
                class="mr-50"
              />
              <span class="font-weight-bold">Boot</span>
            </template>
            <div>
              <camera-edit-tab-boot-logs
                :camera-data="cameraData"
              />
            </div>
          </b-tab>
          <!-- Commands Sent -->
          <b-tab>
            <template #title>
              <feather-icon
                icon="SendIcon"
                size="18"
                class="mr-50"
              />
              <span class="font-weight-bold">Commands Sent</span>
            </template>
            <div>
              <camera-edit-tab-commands-sent-logs
                :camera-data="cameraData"
              />
            </div>
          </b-tab>
          <!-- Commands Qued -->
          <b-tab>
            <template #title>
              <feather-icon
                icon="SendIcon"
                size="18"
                class="mr-50"
              />
              <span class="font-weight-bold">Commands Qued</span>
            </template>
            <div>
              <camera-edit-tab-commands-qued-logs
                :camera-data="cameraData"
              />
            </div>
          </b-tab>
          <!-- Camera Logs -->
          <b-tab>
            <template #title>
              <feather-icon
                icon="CameraIcon"
                size="18"
                class="mr-50"
              />
              <span class="font-weight-bold">Camera Logs</span>
            </template>
            <div>
              <camera-edit-tab-camera-logs
                :camera-data="cameraData"
                :do-refresh="refreshCameraLog"
              />
            </div>
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BTabs, BTab, BRow, BCol, BBadge,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import CameraEditTabCommandsSentLogs from './CameraEditTabCommandsSentLogs.vue'
import CameraEditTabCommandsQuedLogs from './CameraEditTabCommandsQuedLogs.vue'
import CameraEditTabAlertsLogs from './CameraEditTabAlertsLogs.vue'
import CameraEditTabCameraLogs from './CameraEditTabCameraLogs.vue'
import CameraEditTabBootLogs from './CameraEditTabBootLogs.vue'

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BBadge,

    CameraEditTabCommandsSentLogs,
    CameraEditTabCommandsQuedLogs,
    CameraEditTabAlertsLogs,
    CameraEditTabCameraLogs,
    CameraEditTabBootLogs,
  },
  props: {
    cameraData: {
      type: Object,
      default: null,
    },
    refreshCameraLog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tabIndex: 0,
      picToastcount: 0,
    }
  },
  methods: {
    onLogDownloaded() {
      console.log('onLogDownloaded')
      this.tabIndex = 4
      this.refreshCameraLog = true

      // create the Toast notification
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Log has been retreived. See log list.',
          icon: 'CameraIcon',
          variant: 'warning',
        },
      })
    },
  },
}
</script>
